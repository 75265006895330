import st from './Top.module.scss'

import gameImg from './images/game.png'
import icon18 from './images/everyone.png'
import info from './images/info.png'
import star from './images/star.png'
import share from './images/share.svg'
import add from './images/add.png'
import device from './images/devices.png'
import { useIntl } from 'react-intl'

const Top = (props) => {
  const { $t } = useIntl()
  const { downLoadApp } = props

  const clickHandler = () => {
    downLoadApp && downLoadApp()
  }

  return (
    <div className={st.container}>
      <div className={st.content}>
        <div className={st.contentInfo}>
          <div className={st.contentInfoOut}>
            <div>
              <div className={st.infoTitle}>
                <img src={gameImg} alt="" className={st.infoTitleImg} />
                <div className={st.infoTitleContent} >
                  <div className={st.infoName}>{$t({ id: 'AppName' })}</div>
                  <div className={st.infoDesc}>
                    {$t({ id: 'ProductMadness' })}
                  </div>
                  {/* <div className={st.infoDesc2}>
                    {$t({ id: 'InAppPurchases' })}
                  </div> */}
                </div>
              </div>
              <div className={st.infoDownLoad} >
                <div className={st.infoDownLoadItem}>
                  <div className={st.infoDownLoadItemName}>
                    4.9
                    <img src={star} alt="" />
                  </div>
                  <div className={st.infoDownLoadItemDesc}>
                    {$t({ id: 'Reviews' }, { A: '497' })}
                  </div>
                </div>
                <div className={st.infoDownLoadDivider}></div>
                <div className={st.infoDownLoadItem}>
                  <div className={st.infoDownLoadItemName}>10 M+</div>
                  <div className={st.infoDownLoadItemDesc}>
                    {$t({ id: 'Downloads' })}
                  </div>
                </div>
                <div className={st.infoDownLoadDivider}></div>
                <div className={st.infoDownLoadItem}>
                  <div className={st.infoDownLoadItemName}>
                    <img
                      src={icon18}
                      alt=""
                      className={st.infoDownLoadItemName12}
                    />
                  </div>
                  <div className={st.infoDownLoadItemDesc}>
                  {$t({ id: 'age' })}
                    <img src={info} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className={st.gameImgBig}>
              <img src={gameImg} alt="" className={st.gameImgBig1} />
              <img src={gameImg} alt="" className={st.gameImgBig2} />
            </div>
          </div>

          <div className={st.infoHandler}>
            <div className={st.infoHandlerDownload} onClick={clickHandler}>
              {$t({ id: 'Install' })}
            </div>
            <div className={st.infoHandlerShare}>
              <div className={st.infoHandlerShareItem} >
                <img src={share} alt="" />
                <div className={st.infoHandlerShareItemName}>
                  {$t({ id: 'Share' })}
                </div>
              </div>
              <div className={st.infoHandlerShareItem} >
                <img src={add} alt="" />
                <div className={st.infoHandlerShareItemName}>
                  {$t({ id: 'AddToWishlist' })}
                </div>
              </div>
            </div>
          </div>
          <div className={st.infoDevice}>
            <img src={device} alt="" />
            <div>{$t({ id: 'AvailableMessage' })}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Top
