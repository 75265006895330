import st from './Header.module.scss'
import { useEffect, useState } from 'react'
import search from './images/search.png'
import question from './images/question.png'
import google from './images/google.png'
import { useIntl } from 'react-intl'
import googlePlay from './images/google-play.png'

const Header = (props) => {
  const { downLoadApp } = props
  const { $t } = useIntl()

  const [containerStyle, setContainerStyle] = useState({ boxShadow: '' })

  // const clickHandler = () => {
  //   downLoadApp && downLoadApp()
  // }

  const menuList = [
    $t({ id: 'Games' }),
    $t({ id: 'Apps' }),
    $t({ id: 'Movies' }),
    $t({ id: 'Books' }),
    $t({ id: 'Kids' }),
  ]

  useEffect(() => {
    const handlerScroll = () => {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop || 0

      if (scrollTop === 0) {
        setContainerStyle({ boxShadow: '' })
      } else {
        setContainerStyle({
          boxShadow:
            ' 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2)',
        })
      }
    }

    document.addEventListener('scroll', handlerScroll)

    return () => {
      document.removeEventListener('scroll', handlerScroll)
    }
  }, [])

  return (
    <div className={st.container} style={containerStyle}>
      <div className={st.containerTop}>
        <div className={st.containerTopL}>
          <img src={googlePlay} alt="" className={st.googleIcon1} />
          {/* <img src={googleIcon} alt="" className={st.googleIcon} />
          <div className={st.containerTopLTitle}>Google Play</div> */}
          <div className={st.containerTopLMenus}>
            {menuList.map((v, key) => {
              return (
                <div
                  key={key}
                  className={st.containerTopLMenuItem}
                  style={key === 1 ? { color: '#01875f' } : {}}
                  // onClick={clickHandler}
                >
                  {v}
                  {key === 1 && <div className={st.itemBottom}></div>}
                </div>
              )
            })}
          </div>
        </div>
        <div className={st.containerTopR}>
          <div className={st.containerTopRItem} >
            <img src={search} alt="" className={st.itemIcon} />
          </div>
          <div className={st.containerTopRItem} >
            <img src={question} alt="" className={st.itemIcon} />
          </div>
          <div className={st.containerTopRItem} >
            <img src={google} alt="" className={st.googleIcon} />
          </div>
        </div>
      </div>
      <div className={st.containerTopMenus}>
        {menuList.map((v, key) => {
          return (
            <div
              key={key}
              className={st.containerTopMenuItem}
              style={key === 1 ? { color: '#01875f' } : {}}
              // onClick={clickHandler}
            >
              {v}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Header
