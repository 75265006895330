import st from "./Main.module.scss";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import arrowRight from "./images/arrowright.png";
import share from "./images/share.png";
import upload from "./images/upload.png";
import reduce from "./images/reduce.png";
import deleteIcon from "./images/delete.png";
import info from "../Top/images/info.png";
import mobile from "./images/mobile.png";
import pc from "./images/pc.png";
import star from "./images/star.svg";
import halfStar from "./images/star-half.png";
import more from "./images/more.png";
import flag from "./images/flag.png";
import country_en from "./images/country_en.png";
import country_pt from "./images/country_pt.png";
import country_es from "./images/country_es.png";
import country_hi from "./images/country_hi.png";
import down from "./images/down.png";
import starGray from "./images/star-gray.png";
import user2 from "./images/users/user2.png";
import user3 from "./images/users/user3.png";
import game1 from "./images/games/game1.png";
import game2 from "./images/games/game2.png";
import game3 from "./images/games/game3.png";
import game4 from "./images/games/game4.png";
import game5 from "./images/games/game5.png";
import { useIntl } from "react-intl";
import { useEffect, useMemo, useState } from "react";
import { useAppSelector } from "store";

const Main = (props) => {
  const { downLoadApp } = props;
  const { $t } = useIntl();
  const locale = useAppSelector((state) => state.config.locale);
  const [swiperImages, setSwiperImages] = useState([]);

  useEffect(() => {
    const loadImages = async () => {
      const allowLocal = ["en", "pt", "es", "hi"];
      const swiperList = [];
      for (let index = 0; index < 5; index++) {
        const image = await import(
          `./images/swiper/swiper${index + 1}-${allowLocal.includes(locale) ? locale : "en"
          }.jpg`
        );
        swiperList.push(image.default);
      }
      setSwiperImages(swiperList);
    };
    if (locale) {
      loadImages();
    }
  }, [locale]);

  // const clickHandler = () => {
  //   downLoadApp && downLoadApp();
  // };

  const rateList = [
    { num: 5, width: "90%" },
    { num: 4, width: "20%" },
    { num: 3, width: "10%" },
    { num: 2, width: "0%" },
    { num: 1, width: "5%" },
  ];

  const footerList = [
    {
      title: "GooglePlay",
      children: [
        "PlayPass",
        "PlayPoints",
        "GiftCards",
        "Redeem",
        "RefundPolicy",
      ],
    },
    {
      title: "KidsFamily",
      children: ["ParentGuide", "FamilySharing"],
    },
  ];

  const evaluateList = [
    // {
    //   avatar: user1,
    //   userName: "Lisa G",
    //   time: "November 15, 2023",
    //   content:
    //     "Update..plan on spending bank if you want to play. Wins are not enough to sustain playing for a decent amount of time. I agree with the other reviews stating that the winnings become less and less. The more coins you purchase, the more quickly you lose them. After purchasing the piggy bank, the price skyrocketed for any additional purchase. The more dedicated you are to playing, the higher the prices go. There are plenty of other slot apps available that have a much higher win/lose ratio.",
    //   userfulDesc: "2,008 people found this review helpful",
    // },
    {
      avatar: user2,
      userName: "Karen S",
      time: "October 15, 2023",
      content:
        "Great choice of popular games that are also in the casinos live, and lots of fun to play! Watching ads alone can sustain play, but can be agonizingly slow at times. Be prepared to make purchases or have lots of patience. Sometimes, you have to watch a 30 second to a minute long ad in between a few hands of play, and that can go on for several hands. On some games, you can't complete the bonus requirements unless you buy money to play. STILL, my favorite of all the casino apps to play!",
      userfulDesc: "3,394 people found this review helpful",
    },
    {
      avatar: user3,
      userName: "Donita C",
      time: "September 14, 2022",
      content:
        "I really enjoy this game. It has a variety of different slots to play. I can earn extra coins as I play. Not many ads to watch. The amount of coins when buying is lower than other games, but due to the quality of this game, they are worth it in my opinion. I have downloaded and then deleted other apps after playing them.",
      userfulDesc: "1,110 people found this review helpful",
    },
  ];

  const gameList1 = [
    {
      img: game1,
      title: $t({ id: "GameTitle1" }),
      desc: $t({ id: "GameSubTitle1" }),
      star: "4.3",
    },
    {
      img: game2,
      title: $t({ id: "GameTitle2" }),
      desc: $t({ id: "GameSubTitle2" }),
      star: "4.7",
    },
    {
      img: game3,
      title: $t({ id: "GameTitle3" }),
      desc: $t({ id: "GameSubTitle3" }),
      star: "4.8",
    },
    {
      img: game4,
      title: $t({ id: "GameTitle4" }),
      desc: $t({ id: "GameSubTitle4" }),
      star: "4.6",
    },
    {
      img: game5,
      title: $t({ id: "GameTitle5" }),
      desc: $t({ id: "GameSubTitle5" }),
      star: "4.8",
    },
  ];

  const countryIcon = useMemo(() => {
    switch (locale) {
      case "pt":
        return country_pt;
      case "es":
        return country_es;
      case "hi":
        return country_hi;
      default:
        return country_en;
    }
  }, [locale]);

  return (
    <div className={st.container}>
      <div className={st.content}>
        <div className={st.contentLeft}>
          <div className={st.swiperContainer}>
            <Swiper
              slidesPerView="auto"
              className={st.swiperContent}
              navigation={{
                nextEl: "#navigationNext",
                prevEl: ".navigation-prev",
              }}
              spaceBetween={16}
              modules={[Navigation]}
            >
              {swiperImages.map((v, key) => {
                return (
                  <SwiperSlide key={key} style={{ width: "auto" }}>
                    <img
                      src={v}
                      alt=""
                      className={st.swiperImg}
                      // onClick={clickHandler}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
            <div id="navigationNext" className={st.navigationNext}>
              <img
                src={down}
                alt=""
                style={{ transform: "rotate(-90deg)", width: 20, height: 20 }}
              />
            </div>
          </div>
          <div className={st.contentGameInfo}>
            <div className={st.infoTitle} >
              {$t({ id: "AboutGame" })}
              <img src={arrowRight} alt="" />
            </div>
            <div className={st.contentGameInfoText}>
              {$t({ id: "AboutGameContent" }, { B: <br /> })}
            </div>
          </div>

          <div className={st.contentSafe}>
            <div className={st.infoTitle} >
              {$t({ id: "DataSafety" })}
              <img src={arrowRight} alt="" />
            </div>
            <div className={st.contentSafeText}>
              {$t({ id: "DataSafetyContent" })}
            </div>
            <div className={st.contentSafeTable}>
              <div className={st.contentSafeTableItem}>
                <img src={share} alt="" />
                <div>
                  {$t({ id: "DataSafetyMessage1" })}
                  <div className={st.contentSafeTableItemDesc}>
                    {$t({ id: "DataSafetyMessage2" })}
                  </div>
                </div>
              </div>
              <div className={st.contentSafeTableItem}>
                <img src={upload} alt="" />
                <div>
                  {$t({ id: "DataSafetyMessage3" })}
                  <div className={st.contentSafeTableItemDesc}>
                    {$t({ id: "DataSafetyMessage4" })}
                  </div>
                </div>
              </div>
              <div className={st.contentSafeTableItem}>
                <img src={reduce} alt="" />
                <div>{$t({ id: "DataSafetyMessage5" })}</div>
              </div>
              <div className={st.contentSafeTableItem}>
                <img src={deleteIcon} alt="" />
                <div>{$t({ id: "DataSafetyMessage6" })}</div>
              </div>
              <div className={st.contentSafeTableDetail} >
                {$t({ id: "SeeDetails" })}
              </div>
            </div>
          </div>
          <div className={st.contentRate}>
            <div className={st.contentRateTitle}>
              <div className={st.infoTitle} >
                {$t({ id: "RatingsAndReviews" })}
                <img src={arrowRight} alt="" />
              </div>
              <div className={st.contentRateTitleR} >
                {$t({ id: "RatingsMessage" })}
                <img src={info} alt="" />
              </div>
            </div>
            <div className={st.contentRateDevice}>
              <div className={st.contentRateDeviceItem} >
                <img src={mobile} alt="" />
                {$t({ id: "Phone" })}
              </div>
              <div
                className={st.contentRateDeviceItemPc}
                // onClick={clickHandler}
              >
                <img src={pc} alt="" /> {$t({ id: "Tablet" })}
              </div>
            </div>
            <div className={st.contentRateDetail}>
              <div className={st.contentRateDetailL}>
                <div className={st.contentRateDetailLNum}>4.9</div>
                <div className={st.contentRateDetailLStar}>
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={halfStar} alt="" />
                </div>
                <div className={st.contentRateDetailLNumUser}>
                  {$t({ id: "ReviewsCount" }, { A: "422" })}
                </div>
              </div>
              <div className={st.contentRateDetailR}>
                {rateList.map((v, key) => {
                  return (
                    <div key={key} className={st.contentRateDetailRItem}>
                      <div className={st.contentRateDetailRItemNum}>
                        {v.num}
                      </div>
                      <div className={st.contentRateDetailRItemProgress}>
                        <div
                          className={st.contentRateDetailRItemProgressInner}
                          style={{ width: v.width }}
                        ></div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={st.contentRateEvaluate}>
              {evaluateList.map((v, key) => {
                return (
                  <div
                    key={key}
                    className={st.contentRateEvaluateItem}
                    // onClick={clickHandler}
                  >
                    <div className={st.contentRateEvaluateItemTitle}>
                      <img
                        src={v.avatar}
                        alt=""
                        className={st.contentRateEvaluateItemTitleAvatar}
                      />
                      <div className={st.contentRateEvaluateItemTitleName}>
                        {v.userName}
                      </div>
                      <img
                        src={more}
                        alt=""
                        className={st.contentRateEvaluateItemTitleMore}
                      />
                    </div>
                    <div className={st.contentRateEvaluateItemRate}>
                      <img src={star} alt="" />
                      <img src={star} alt="" />
                      <img src={star} alt="" />
                      <img src={star} alt="" />
                      <img src={star} alt="" />
                      <div>{v.time}</div>
                    </div>
                    <div className={st.contentRateEvaluateItemContent}>
                      {v.content}
                    </div>
                    <div className={st.contentRateEvaluateItemUserful}>
                      {v.userfulDesc}
                    </div>
                    <div className={st.contentRateEvaluateItemIsUserful}>
                      <div className={st.contentRateEvaluateItemIsUserfulText}>
                        {$t({ id: "Helpful" })}
                      </div>
                      <div className={st.contentRateEvaluateItemIsUserfulBtn}>
                        {$t({ id: "Yes" })}
                      </div>
                      <div className={st.contentRateEvaluateItemIsUserfulBtn}>
                        {$t({ id: "No" })}
                      </div>
                    </div>
                  </div>
                );
              })}

              <div className={st.contentRateEvaluateMore}>
                {$t({ id: "SeeAllReviews" })}
              </div>
            </div>
          </div>
          <div className={st.contentNew}>
            <div className={st.infoTitle} >
              {$t({ id: "WhatIsNew" })}
            </div>
            <div className={st.contentNewContent}>
              {$t({ id: "WhatIsNewMsg1" })}
              <br />
              {$t({ id: "WhatIsNewMsg2" })}
              <br />
            </div>
          </div>
        </div>
        {/* <div className={st.contentRight}>
          <div className={st.infoTitle} onClick={clickHandler}>
            {$t({ id: "AppSupport" })}
            <img src={down} alt="" />
          </div>
          <div className={st.contentRightOther}>
            <div className={st.infoTitle} onClick={clickHandler}>
              {$t({ id: "SimilarGames" })}
              <img src={arrowRight} alt="" />
            </div>
            <div className={st.contentRightOtherGameList}>
              {gameList1.map((v, key) => {
                return (
                  <div
                    key={key}
                    className={st.contentRightGameItem}
                    onClick={clickHandler}
                  >
                    <img
                      src={v.img}
                      alt=""
                      className={st.contentRightGameItemImg}
                    />
                    <div className={st.contentRightGameItemImfo}>
                      <div className={st.contentRightGameItemImfoTitle}>
                        {v.title}
                      </div>
                      <div className={st.contentRightGameItemImfoDesc}>
                        {v.desc}
                      </div>
                      <div className={st.contentRightGameItemImfoStar}>
                        {v.star}
                        <img src={starGray} alt="" />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div> */}
      </div>
      <div className={st.report} >
        <img src={flag} alt="" />
        {$t({ id: "FlagAsInappropriate" })}
      </div>
      <div className={st.footer}>
        <div className={st.divider}></div>
        <div className={st.footerInfo}>
          {footerList.map((v, key) => {
            return (
              <div key={key} className={st.footerInfoItem}>
                <div className={st.footerInfoItemTitle} >
                  {$t({ id: v.title })}
                </div>
                {v.children.map((v1, key1) => {
                  return (
                    <div
                      key={key1}
                      className={st.footerInfoItemText}
                      
                    >
                      {$t({ id: v1 })}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
      <div className={st.desc} >
        <div className={st.descItem}>{$t({ id: "TermsService" })}</div>
        <div className={st.descItem}>{$t({ id: "Privacy" })}</div>
        <div className={st.descItem}>{$t({ id: "AboutGooglePlay" })}</div>
        <div className={st.descItem}>{$t({ id: "Developers" })}</div>
        <div className={st.descItem}>{$t({ id: "GoogleStore" })}</div>

        <div className={st.descItemCountry}>
          <img src={countryIcon} alt="" />
          <div>{$t({ id: "UnitedStates" })}</div>
        </div>
      </div>
    </div>
  );
};

export default Main;
